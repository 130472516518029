<template>
  <div class="web-footer">
    <!-- 底部公共组件 -->
    <div class="web-footer-one">
      <div class="one-title">和30万+企业一起，用AI员工提升品牌30%复购增长</div>
      <div class="one-search">
        <el-input v-model="phone" class="search-input" placeholder="请输入您的手机号码" />
        <div class="one-search-btn" @click="experienceClick">马上试用</div>
      </div>
    </div>
    <div class="web-footer-two">
      <img src="../../assets/img/footer/插画@2x.png" />
    </div>
    <div class="web-footer-three">
      <div class="three-item">
        <div class="title-round">
          <div class="round" />
          <div class="title">产品矩阵</div>
        </div>
        <div class="item" v-for="(item, index) in productData" :key="index" @click="goPage(item.url)"
          :style="item.url ? 'cursor: pointer' : ''">
          {{ item.name }}
        </div>
      </div>
      <div class="three-item">
        <div class="title-round">
          <div class="round" />
          <div class="title">解决方案</div>
        </div>
        <div class="item" v-for="(item, index) in solvePlanData" :key="index">
          {{ item }}
        </div>
      </div>
      <div class="three-item">
        <div class="title-round">
          <div class="round" />
          <div class="title">关于及猫</div>
        </div>
        <div class="item" v-for="(item, index) in aboutCatData" :key="index" @click="goPage(item.url)"
          :style="item.url ? 'cursor: pointer' : ''">
          {{ item.name }}
        </div>
      </div>
      <div class="three-item-img">
        <div class="title-round">
          <div class="round" />
          <div class="title">权威认证</div>
        </div>
        <div class="content" v-for="item in safeData" :key="item.id">
          <img :src="item.img" class="content-left" />
          <div class="content-right">
            <div class="content-right-tit-english">{{ item.engTit }}</div>
            <div class="content-right-tit">{{ item.tit }}</div>
          </div>
        </div>
      </div>
      <div class="three-item-code">
        <div class="title-round">
          <div class="round" />
          <div class="title">联系我们</div>
        </div>
        <div class="code-url">邮箱：zhaoxiuli@sioo.cn</div>
        <div class="code-url">链接：www.sioo.cn</div>
        <div class="code-url">电话：400-888-7686<br/>&emsp;&emsp;&emsp;400-699-7687</div>
        <div class="code-img">
          <div>
            <img src="../../assets/img/footer/二维码one@2x.png" alt />
            <div class="code-name">微信公众号</div>
          </div>
          <div class="code-img-item">
            <img src="../../assets/img/footer/二维码two@2x.png" alt />
            <div class="code-name">及猫云视频号</div>
          </div>
        </div>
      </div>
    </div>
    <div class="web-footer-four">
      <div class="four-left">
        Copyright © 2024 Gmall All Rights Reserved.<span style="cursor: pointer"
          @click="goInformattion">沪ICP备06059470号-5</span>,<span style="cursor: pointer"
          @click="goInformattion">沪ICP备06059470号-15</span>
      </div>
      <div class="four-right">
        <img src="../../assets/img/footer/police@2x.png" alt />
        <div class="content">沪公网安备 30339393030号</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'web-footer',
  data () {
    return {
      phone: '',
      productData: [
        {
          name: '及猫云掘贝通',
          url: 'digBeitong'
        },
        {
          name: '及猫云智能机器人GMAI',
          url: ''
        },
        {
          name: '及猫云呼叫系统',
          url: 'callSystem'
        },
        {
          name: '及猫云企微助手',
          url: ''
        }
      ],
      solvePlanData: ['软件行业', '电商行业', '医药行业', '商超行业'],
      aboutCatData: [
        {
          name: '企业介绍',
          url: 'aboutUs'
        },
        {
          name: '联系我们',
          url: 'aboutUs'
        },
        {
          name: '加入我们',
          url: 'joinUs'
        },
        {
          name: '业务动态',
          url: ''
        }
      ],
      safeData: [
        // {
        //   id: 1,
        //   img: require('../../assets/img/footer/csa@2x.png'),
        //   engTit: 'CSA STAR C ertification',
        //   tit: '体系国际认证'
        // },
        // {
        //   id: 2,
        //   img: require('../../assets/img/footer/iso@2x.png'),
        //   engTit: 'ISO9001信息安全管理',
        //   tit: '体系国际认证'
        // }
        // {
        //   id: 3,
        //   img: require('../../assets/img/footer/可信云@2x.png'),
        //   engTit: '可信云企业级SAAS服务',
        //   tit: 'NO：07040'
        // }
      ]
    }
  },
  methods: {
    experienceClick () {
      // var str = location.href.split('#')[0]
      // window.open(str + '#/messageInformation', '_blank')
      this.$router.push({
        name: 'MessageInformation',
        query: { phone: this.phone }
      })
    },
    goPage (url) {
      if (url) {
        this.$router.push(url)
      }
    },
    goInformattion () {
      window.open('http://beian.miit.gov.cn/', '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.web-footer {
  width: 100%;
  padding-top: 120px;

  .web-footer-one {
    margin-left: 134px;
    margin-right: 153px;
    height: 387px;
    background: url('../../assets/img/圆角矩形back@2x.png');
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .one-title {
      font-size: 38px;
      font-family: SourceHanSansCN;
      font-weight: bold;
      color: #000000;
      margin-top: 92px;
      margin-bottom: 53px;
    }

    .one-search {
      width: 889px;
      height: 65px;
      background: #ffffff;
      border: 2px solid #000000;
      border-radius: 50px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .search-input {
        margin-top: 8px;
        margin-left: 20px;
        width: 684px;

        ::v-deep .el-input__inner {
          border: none;
          font-size: 22px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #262626;
          line-height: 33px;
        }

        ::-webkit-input-placeholder {
          color: #262626;
          letter-spacing: 4px;
        }
      }

      .one-search-btn {
        font-size: 22px;
        font-family: PingFang SC;
        letter-spacing: 4px;
        font-weight: 400;
        color: #ffe50c;
        line-height: 50px;
        width: 148px;
        height: 50px;
        background: #262626;
        border-radius: 41px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 4px;
        margin-right: 8px;
        cursor: pointer;
      }
    }
  }

  .web-footer-two {
    margin-left: 173px;
    margin-right: 162px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .web-footer-three {
    width: 100%;
    height: 640px;
    background: url('../../assets/img/footer/back_footer@2x.png');
    background-size: 100% 100%;
    margin-top: -130px;
    display: flex;
    flex-direction: row;
    padding-top: 220px;
    padding-left: 150px;

    .three-item {
      margin-right: 157px;

      .title-round {
        display: flex;

        .round {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          margin-top: 4px;
          margin-left: -8px;
          background: #fae185;
        }

        .title {
          font-size: 22px;
          font-family: PingFang SC;
          font-weight: 600;
          color: #071b3c;
          margin-bottom: 34px;
          margin-left: -16px;
        }
      }

      .item {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
        margin-top: 23px;
        // cursor: pointer;
      }
    }

    .three-item-img {
      margin-right: 127px;

      .title-round {
        display: flex;

        .round {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          margin-top: 4px;
          margin-left: -8px;
          background: #fae185;
        }

        .title {
          font-size: 22px;
          font-family: PingFang SC;
          font-weight: 600;
          color: #071b3c;
          margin-bottom: 34px;
          margin-left: -16px;
        }
      }

      .content {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 18px;

        .content-left {
          width: 47px;
          height: 46px;
          margin-right: 35px;
        }

        .content-right {
          .content-right-tit-english {
            font-size: 18px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #333333;
          }

          .content-right-tit {
            font-size: 18px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #333333;
          }
        }
      }
    }

    .three-item-code {
      .title-round {
        display: flex;

        .round {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          margin-top: 4px;
          margin-left: -8px;
          background: #fae185;
        }

        .title {
          font-size: 22px;
          font-family: PingFang SC;
          font-weight: 600;
          color: #071b3c;
          margin-bottom: 34px;
          margin-left: -12px;
        }
      }

      .code-url {
        margin-top: 17px;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
      }

      .code-img {
        display: flex;
        flex-direction: row;
        margin-top: 19px;

        .code-img-item {
          margin-left: 17px;
        }

        .code-name {
          margin-top: 19px;
          font-size: 18px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #333333;
        }

        img {
          width: 117px;
          height: 116px;
        }
      }
    }
  }

  .web-footer-four {
    width: 100%;
    height: 56px;
    background: #262626;
    color: #ffffff;
    font-family: PingFang SC;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .four-left {
      font-size: 16px;
      margin-right: 105px;
    }

    .four-right {
      display: flex;
      flex-direction: row;

      img {
        width: 21px;
        height: 23px;
        margin-right: 12px;
      }

      .content {
        font-size: 16px;
      }
    }
  }
}</style>
