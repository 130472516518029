<template>
  <div class="web-header-all">
    <div class="web-header">
      <div class="web-header-left">
        <img
          src="../../assets/img/menu@2x.png"
          alt="菜单"
          class="menu"
          @click="menuClick"
        >
        <img
          src="../../assets/img/及猫云logo@2x.png"
          alt="及猫云logo"
          class="logo"
          @click="goBackHome"
        >
        <div class="line" />
        <div class="tip-content">
          <div class="tip-content__text">全球AI通信能力开放平台</div>
          <div class="tip-content__phone">400-888-7686</div>
        </div>
      </div>
      <div class="web-header-right">
        <div
          class="btn-border"
          @click="loginClick"
        >立即登录</div>
        <div
          class="btn-back"
          @click="experienceClick"
        >体验DEMO</div>
      </div>
    </div>
    <div
      class="web-menu"
      v-show="isMenu"
      @mouseover="mouseIsBlock(true)"
      @mouseout="mouseIsBlock(false)"
    >
      <div class="menu-left">
        <div
          v-for="item in menuData"
          :key="item.id"
          :class="item.id === activeId ? 'left-item active' : 'left-item'"
        >
          <img
            :class="item.imgName"
            :src="item.img"
            alt
          />
          <div
            class="title"
            @mouseover="menuTab(item.id)"
            @click="menuGo(item.url)"
          >{{ item.title }}</div>
        </div>
      </div>
      <div class="menu-right">
        <div
          class="right-item"
          v-for="item in menuContData.contentData"
          :key="item.id"
          @click="skipClick(item.url)"
        >
          <img
            :src="item.img"
            :class="item.url"
          >
          <div class="title">{{ item.title }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'web-header',
  data () {
    return {
      isMenu: false,
      activeId: 1,
      menuData: [
        {
          id: 1,
          title: '产品',
          img: require('../../assets/img/产品@2x.png'),
          imgName: 'product',
          contentData: [
            {
              id: 1,
              title: '云呼叫系统',
              url: 'callSystem',
              img: require('../../assets/img/24gl-phoneLoudspeaker@2x.png')
            },
            {
              id: 2,
              title: '掘贝通',
              url: 'digBeitong',
              img: require('../../assets/img/获取系统信息@2x.png')
            }
          ]
        },
        {
          id: 2,
          title: '解决方案',
          img: require('../../assets/img/解决方案@2x.png'),
          imgName: 'solve',
          contentData: []
        },
        {
          id: 3,
          title: '服务支持',
          img: require('../../assets/img/服务支持@2x.png'),
          imgName: 'service',
          contentData: [
            {
              id: 1,
              title: '市场活动',
              url: 'marketActivity',
              img: require('../../assets/img/市场活动@2x.png')
            },
            {
              id: 2,
              title: '产品支持',
              url: 'productSupport',
              img: require('../../assets/img/产品 (1)@2x.png')
            },
            {
              id: 3,
              title: '合作代理',
              url: 'cooperationAgent',
              img: require('../../assets/img/合作代理@2x.png')
            }
          ]
        },
        {
          id: 4,
          title: '新闻动态',
          img: require('../../assets/img/24gl-newspaper3@2x.png'),
          imgName: 'news',
          contentData: [],
          url: 'newsEvents'
        },
        {
          id: 5,
          title: '关于及猫云',
          img: require('../../assets/img/关于及猫云@2x.png'),
          imgName: 'jimaoyun',
          contentData: [
            {
              id: 1,
              title: '企业介绍',
              url: 'aboutUs',
              img: require('../../assets/img/公司简介@2x.png')
            },
            {
              id: 2,
              title: '联系我们',
              url: 'aboutUs',
              img: require('../../assets/img/联系我们@2x.png')
            },
            {
              id: 3,
              title: '加入我们',
              url: 'joinUs',
              img: require('../../assets/img/加入团队@2x.png')
            }
          ]
        }
      ],
      menuContData: []
    }
  },
  methods: {
    loginClick () {
      window.open('https://vip.gmall.com.cn/#/login', '_blank')
      // const currentUrl = window.location.href
      // // console.log(currentUrl)
      // const newUrl = currentUrl.replace(
      //   'http://localhost:8080',
      //   'https://vip.gmall.com.cn/#/login'
      // )
      // // console.log(newUrl)
      // window.open(newUrl, '_blank')
    },
    experienceClick () {
      // window.open('https://uxus7n.fanqier.cn/f/i9x2f1e6', '_blank')
      var str = location.href.split('#')[0]
      window.open(str + '#/messageInformation', '_blank')
    },
    menuClick () {
      this.isMenu = !this.isMenu
    },
    filterCont () {
      this.menuContData = this.menuData.filter(item => item.id === this.activeId)[0]
    },
    menuTab (id) {
      this.activeId = id
      this.menuContData = this.menuData.filter(item => item.id === id)[0]
    },
    skipClick (url) {
      this.$router.push('/' + url)
    },
    mouseIsBlock (val) {
      this.isMenu = val
    },
    goBackHome () {
      this.$router.push('/')
    },
    menuGo (url) {
      if (url) {
        this.$router.push(url)
      }
    }
  },
  mounted () {
    this.filterCont()
  }
}
</script>

<style lang="scss" scoped>
.web-header-all {
  width: 100%;
  height: 100px;
}
.web-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background: #ffe50c;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 50px 120px 54px 87px;
  z-index: 100;
  .web-header-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    .menu {
      width: 37px;
      height: 26px;
      margin-right: 56px;
      cursor: pointer;
    }
    .logo {
      width: 148px;
      height: 66px;
      cursor: pointer;
    }
    .line {
      width: 1px;
      height: 44px;
      background: #071b3c;
      margin: 0 16px;
    }
    .tip-content {
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #29343b;
      line-height: 25px;
      &__phone{
        margin-top: 5px;
      }
    }
  }
  .web-header-right {
    display: flex;
    flex-direction: row;
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    .btn-border {
      width: 136px;
      height: 46px;
      border: 2px solid #071b3c;
      border-radius: 24px;
      color: #071b3d;
      line-height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 29px;
      cursor: pointer;
    }
    .btn-back {
      width: 136px;
      height: 46px;
      background: #061a3c;
      border-radius: 24px;
      color: #ffd400;
      line-height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &:hover {
        color: #071b3d;
        background: #ffe50c;
        border: 2px solid #071b3c;
      }
    }
  }
}
.web-menu {
  position: fixed;
  width: 1690px;
  height: 565px;
  background: #ffffff;
  box-shadow: 0px 2px 70px 9px rgba(0, 0, 0, 0.08);
  z-index: 100;
  top: 104px;
  left: 86px;
  display: flex;
  flex-direction: row;
  .menu-left {
    width: 315px;
    height: 100%;
    background: #f8f8f8;
    padding-top: 60px;
    padding-left: 41px;
    .left-item {
      width: 219px;
      height: 74px;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 25px;
      padding-left: 57px;
    }
    .left-item.active {
      background: rgba(255, 229, 12, 0.2);
    }
    .product {
      width: 22px;
      height: 22px;
    }
    .solve {
      width: 20px;
      height: 23px;
    }
    .service {
      width: 23px;
      height: 24px;
    }
    .news {
      width: 20px;
      height: 21px;
    }
    .jimaoyun {
      width: 15px;
      height: 24px;
    }
    .title {
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #29343b;
      line-height: 25px;
      margin-left: 6px;
      cursor: pointer;
    }
  }
  .menu-right {
    padding-left: 54px;
    padding-top: 94px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .right-item {
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #333333;
      line-height: 25px;
      margin-right: 67px;
      display: flex;
      &:hover {
        color: #ffe50c;
        cursor: pointer;
      }
      img {
        margin-right: 7px;
        margin-top: 3px;
      }
      .callSystem {
        width: 21px;
        height: 21px;
      }
      .digBeitong {
        width: 22px;
        height: 22px;
      }
      .marketActivity {
        width: 20px;
        height: 20px;
      }
      .productSupport {
        width: 21px;
        height: 22px;
      }
      .cooperationAgent {
        width: 22px;
        height: 19px;
      }
      .aboutUs {
        width: 23px;
        height: 23px;
      }
      .joinUs {
        width: 21px;
        height: 23px;
      }
    }
  }
}
</style>
