<template>
  <div class="register"></div>
</template>

<script>
export default {
  name: 'Register'
}
</script>

<style lang="scss" scoped>

</style>
