import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import VueVideoPlayer from 'vue-video-player'
import * as echarts from 'echarts'
import 'video.js/dist/video-js.css'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/styles/index.scss' // global css
import axios from 'axios'

Vue.prototype.$echarts = echarts
Vue.config.productionTip = false
Vue.use(ElementUI)

Vue.prototype.axios = axios
Vue.use(
  VueVideoPlayer /* {
  options: global default options,
  events: global videojs events
} */
)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
