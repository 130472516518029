<template>
  <div class="web-cooperate">
    <!-- 合作伙伴/客户 组件 -->
    <div class="web-cooperate-head">
      <div class="head-title">
        <div class="head-round" />
        <div class="head-tit">{{ title }}</div>
      </div>
      <div class="head-tit-english">
        {{ tip ? tip : 'Our partners' }}
      </div>
    </div>
    <div class="web-cooperate-foot">
      <img
        v-for="(item, index) in imgData"
        :key="index"
        :src="item"
        alt
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'web-cooperate',
  props: ['title', 'tip'],
  data () {
    return {
      imgData: [
        require('../../assets/img/cooperate/channel.png'),
        require('../../assets/img/cooperate/dr.png'),
        require('../../assets/img/cooperate/dior.png'),
        require('../../assets/img/cooperate/zhy.png'),
        require('../../assets/img/cooperate/hxz.png'),
        require('../../assets/img/cooperate/sypm.png'),
        require('../../assets/img/cooperate/marionnaud@2x.png'),
        require('../../assets/img/cooperate/zj.png'),
        require('../../assets/img/cooperate/qt.png'),
        require('../../assets/img/cooperate/duola.png')

      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.web-cooperate {
  width: 100%;
  .web-cooperate-head {
    display: flex;
    flex-direction: column;
    align-items: center;
    .head-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 119px;
      margin-bottom: 21px;
      .head-round {
        width: 25px;
        height: 25px;
        background: #ffd400;
        border-radius: 50%;
        margin-top: 14px;
        margin-right: -20px;
      }
      .head-tit {
        font-size: 50px;
        font-family: SourceHanSansCN;
        font-weight: bold;
        color: #000000;
      }
    }
    .head-tit-english {
      font-size: 26px;
      font-family: SourceHanSansCN;
      font-weight: 500;
      color: rgba(7, 27, 61, 0.55);
      margin-left: 5px;
    }
  }
  .web-cooperate-foot {
    margin-top: 162px;
    margin-left: 256px;
    margin-right: 254px;
    img {
      width: 220px;
      height: 105px;
      margin-right: 45px;
      margin-bottom: 20px;
    }
  }
}
</style>
