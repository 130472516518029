import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/login.vue'
import Register from '../views/register.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      keep: 1
    }
  },
  {
    path: '/aboutUs',
    name: 'AboutUs',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutUs.vue')
  },
  {
    path: '/productSupport',
    name: 'ProductSupport',
    component: () => import(/* webpackChunkName: "about" */ '../views/ProductSupport.vue')
  },
  {
    path: '/callSystem',
    name: 'CallSystem',
    component: () => import(/* webpackChunkName: "about" */ '../views/CallSystem.vue')
  },
  {
    path: '/digBeitong',
    name: 'DigBeitong',
    component: () => import(/* webpackChunkName: "about" */ '../views/DigBeitong.vue')
  },
  {
    path: '/joinUs',
    name: 'JoinUs',
    component: () => import(/* webpackChunkName: "about" */ '../views/JoinUs.vue')
  },
  {
    path: '/marketActivity',
    name: 'MarketActivity',
    component: () => import(/* webpackChunkName: "about" */ '../views/MarketActivity.vue')
  },
  {
    path: '/cooperationAgent',
    name: 'CooperationAgent',
    component: () => import(/* webpackChunkName: "about" */ '../views/CooperationAgent.vue')
  },
  {
    path: '/newsEvents',
    name: 'NewsEvents',
    component: () => import(/* webpackChunkName: "about" */ '../views/NewsEvents.vue')
  },
  {
    path: '/onlineTrade',
    name: 'OnlineTrade',
    component: () => import(/* webpackChunkName: "about" */ '../views/OnlineTrade.vue')
  },
  {
    path: '/financeTaxation',
    name: 'FinanceTaxation',
    component: () => import(/* webpackChunkName: "about" */ '../views/FinanceTaxation.vue')
  },
  {
    path: '/educationSector',
    name: 'educationSector',
    component: () => import(/* webpackChunkName: "about" */ '../views/educationSector.vue')
  },
  {
    path: '/messageInformation',
    name: 'MessageInformation',
    component: () => import(/* webpackChunkName: "about" */ '../views/MessageInformation.vue')
  }
  // {
  //   path: '/digCustomer',
  //   name: 'DigCustomer',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/digCustomer.vue')
  // }
]

const router = new VueRouter({
  routes
})
export default router
