<template>
  <div class="fixed-dialog" v-if="isClose">
    <div class="fixed-dialog-left">现在注册，立即领取免费试用机会！</div>
    <div class="fixed-dialog-right">
      <div class="btn" @click="experienceClick">马上试用</div>
      <img
        src="../../assets/img/home/关闭@2x.png"
        alt
        @click="close"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'fixed-dialog',
  data () {
    return {
      isClose: true
    }
  },
  methods: {
    close () {
      this.isClose = false
    },
    experienceClick () {
      var str = location.href.split('#')[0]
      window.open(str + '#/messageInformation', '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.fixed-dialog {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 103px;
    background: rgba(0, 0, 0, 0.57);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    z-index: 3;
    .fixed-dialog-left {
      font-size: 28px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      margin-left: 85px;
      margin-top: 36px;
    }
    .fixed-dialog-right {
      display: flex;
      flex-direction: row;
      margin-right: 124px;
      .btn {
        font-size: 21px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #171918;
        width: 136px;
        height: 50px;
        background: #FFE50B;
        border-radius: 41px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 28px;
        line-height: 50px;
        cursor: pointer;
      }
      img {
        width: 16px;
        height: 16px;
        margin-left: 12px;
        margin-top: 15px;
      }
    }
  }
</style>
