<template>
  <div class="home">
    <web-header />
    <el-carousel
      :autoplay="true"
      class="home-carousel"
      arrow="never"
      :interval="5000"
    >
      <!-- <el-carousel-item
        v-for="item in 3"
        :key="item"
      >
        <div
          class="home-head"
          v-if="(item != 2)"
        >
          <div class="home-head-left">
            <div class="home-head-title">
              <div class="round">
                <div class="round-one" />
                <div class="round-two" />
              </div>
              <div class="title">及猫云呼叫系统</div>
            </div>
            <div class="home-head-content">助力全流程销售，一站式服务</div>
            <div class="home-head-detail-content">智能化客户管理与销售管理，高效触达客户，降本增效解决企业电销难题，提升企业业绩，助力传统企业数字化转型。</div>
          </div>
          <div class="home-head-right">
            <img
              src="../assets/img/home/头部展示@2x.png"
              alt="首页电视图"
              class="show-img"
            >
            <img
              src="../assets/img/home/播放@2x.png"
              alt="首页电视图"
              class="open-img"
              @click="videoClick(true)"
            >
          </div>
        </div>
        <div
          class="home-head"
          v-if="(item == 2)"
        >
          <div class="home-center-left">
            <div class="home-center-title">
              <div class="round">
                <div class="round-one" />
                <div class="round-two" />
              </div>
              <div class="title">云呼叫中心</div>
            </div>
            <div class="home-center-content">一键呼叫客户信息，重要通话可录音不丢失</div>
            <div class="home-center-btn">立即体验</div>
          </div>
          <img
            src="../assets/img/home/call_center.png"
            alt="云呼叫中心"
            class="call-center"
          >
        </div>
      </el-carousel-item> -->
      <el-carousel-item v-for="item in 4" :key="item">
        <div class="home-head" v-if="item == 1">
          <div class="first-page-bgc"/>
          <div class="home-head-left">
            <div class="home-head-title">
              <div class="round">
                <div class="first-round-one" />
                <div class="first-round-two" />
              </div>
              <div class="first-title">希奥信息</div>
            </div>
            <div class="home-first-head-content">荣获了上海市“专精特新”中小企业称号</div>
            <div class="home-head-detail-content">
              上海市经济和信息化委员会公示 2023年年度第一批企业名单
            </div>
          </div>
          <div class="home-head-right">
            <img
              src="../assets/img/home/首页轮播第一张1.png"
              alt="专精特新"
              class="show-img-first"
            />
          </div>
        </div>
        <div class="home-head" v-if="item == 2">
          <div class="home-head-left">
            <div class="home-head-title">
              <div class="round">
                <div class="round-one" />
                <div class="round-two" />
              </div>
              <div class="outer-title">
                <div class="title">
                  用
                  <img src="../assets/img/home/矢量智能对象@2x.png" />
                  赋能场景
                </div>

                <div class="title-one">让复购持续发生...</div>
              </div>
            </div>
            <div class="home-head-content"></div>
            <div class="home-head-detail-content"></div>
          </div>
          <div class="home-head-right-one">
            <img
              src="../assets/img/home/动图2.gif"
              alt="首页电视图"
              class="show-img-one"
            />
          </div>
        </div>
        <div class="home-head" v-if="item == 3">
          <div class="home-center-left">
            <div class="home-center-title">
              <div class="round">
                <div class="round-one" />
                <div class="round-two" />
              </div>
              <div class="title">AI VoiceBot大脑</div>
            </div>
            <div class="home-center-content">
              百万并发触达能力,帮助会员企业千人千面触达会员 <br />
              实现精细化会员激活、智能化ROI运营。
            </div>
            <div class="home-center-btn" @click="experienceClick">立即体验</div>
          </div>
          <img
            src="../assets/img/home/call_center.png"
            alt="云呼叫中心"
            class="call-center"
          />
        </div>
        <div class="home-head" v-if="item == 4">
          <div class="home-head-left">
            <div class="home-head-title">
              <div class="round">
                <div class="round-one" />
                <div class="round-two" />
              </div>
              <div class="title">工作手机系统插件</div>
            </div>
            <div class="home-head-content">助力连锁门店导购数智化升级</div>
            <div class="home-head-detail-content">
              导购电话插件打开门店导购与会员复购的第一扇门，在对的时间，和对的人，讲了对的话，实现业绩倍增。
            </div>
          </div>
          <div class="home-head-right">
            <img
              src="../assets/img/home/头部展示@2x.png"
              alt="首页电视图"
              class="show-img"
            />
            <img
              src="../assets/img/home/播放@2x.png"
              alt="首页电视图"
              class="open-img"
              @click="videoClick(true)"
            />
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
    <div class="home-picture">
      <!-- <div class="home-piceture-title">
        <div class="round" />
        <div class="title">
          智能融合通信一体化插件 助力开发者生态<span>高效</span>增长
        </div>
      </div> -->
      <div class="home-picture-one">
        <div class="one-left">
          <div class="one-left-title">
            <div class="title">及猫云AI语音机器人</div>
            <div class="underline" />
          </div>
          <div class="one-left-content-one">让收入30%的增长来自会员运营</div>
          <!-- <div class="one-left-content-two">88%企业效率提升</div> -->
          <div class="one-left-btn">马上查看</div>
        </div>
        <div class="one-right">
          <div class="one-right-tit-little">AI VoiceBOT总数</div>
          <div class="one-right-tit-big" id="num"></div>
          <div class="broken-line">
            <div ref="chart" style="width: 100%; height: 230px"></div>
          </div>
        </div>
      </div>

      <div class="banner-xia">
        <img src="../assets/img/home/头部下@2x.png" />
      </div>

      <div class="home-picture-two">
        <div class="home-picture-all" v-for="item in moduleDate" :key="item.id">
          <div class="home-picture-two-head">
            <div class="title-img">
              <img :src="item.img" />
              <div class="title">{{ item.tit }}</div>
            </div>
            <div class="content">{{ item.content }}</div>
          </div>
          <div class="home-picture-two-content">
            <div class="content-num">{{ item.contentNum }}</div>
            <div class="content-text">{{ item.contentText }}</div>
          </div>
          <div class="home-picture-two-footer">查看</div>
        </div>
      </div>
      <div class="home-picture-three">
        <div
          class="home-picture-three-all"
          v-for="item in moduleTwoDate"
          :key="item.id"
        >
          <div class="three-all-left">
            <div class="three-all-head">
              <div class="head-tit">{{ item.tit }}</div>
              <div class="head-content">{{ item.content }}</div>
            </div>
            <div class="three-all-content">
              <div class="three-content-num">{{ item.contentNum }}</div>
              <div class="three-content-text">{{ item.contentText }}</div>
            </div>
            <div class="three-all-btn">查看</div>
          </div>
          <img :src="item.img" class="three-all-right" />
        </div>
      </div>
      <!-- <div class="at-one-try-out">
        <div class="btn">马上试用</div>
      </div> -->
      <div class="home-record">
        <div class="home-record-title">
          <div class="home-record-round" />
          <div class="title">30万家企业的信赖之选</div>
        </div>
        <div class="home-record-content">
          <!-- <img
            src="../assets/img/home/图层 628@2x.png"
            alt="展示图片"
            class="record-left-img"
          /> -->
          <img class="content-img" src="../assets/img/home/AI大脑@2x.png" />
          <div class="record-right">
            <div
              class="record-content-right"
              v-for="item in recordList"
              :key="item.id"
            >
              <div class="left">
                <div class="record-content-num" v-show="item.num !== '10万+'">
                  {{ item.num }}
                </div>
                <div class="record-content-num" v-show="item.num === '10万+'">
                  10<span>万</span>+
                </div>
                <div class="record-content-text">{{ item.text }}</div>
              </div>
              <img :src="item.img" alt="展示图片" v-if="item.img" />
            </div>
          </div>
        </div>
      </div>
      <div class="home-picture-four">
        <img
          src="../assets/img/home/多个盒子@2x.png"
          class="home-piceture-four-left"
        />
        <div class="home-piceture-four-right">
          <div class="four-tit-left">
            <div class="tit-left-head">
              <div class="head-title">马上成为及猫云开发者</div>
              <div class="head-line" />
            </div>
            <div class="tit-left-foot">
              <div
                class="foot-trigonum"
                v-for="(item, index) in 3"
                :key="index"
              />
              <div class="foot-title">链接全球</div>
            </div>
          </div>
          <div class="four-picture-right">
            <img
              src="../assets/img/home/纸飞机@2x.png"
              class="four-picture-right-one"
            />
            <img
              src="../assets/img/home/dotted_line@2x.png"
              class="four-picture-right-two"
            />
          </div>
        </div>
      </div>

      <div class="home-picture-five">
        <div class="home-picture-five-head">
          <div class="five-head">
            <div class="five-head-round" />
            <div class="five-head-tit">我们的优势</div>
          </div>
          <div class="five-head-tit-english">Our advantages</div>
        </div>
        <div class="home-picture-five-foot">
          <el-carousel height="504px" :autoplay="false" arrow="never">
            <!-- <el-carousel-item
              v-for="item in 3"
              :key="item"
            >
              <div class="foot-carousel-content">
                <div class="carousel-content-left">
                  <div class="carousel-content-left-one">
                    <img
                      src="../assets/img/home/数据展示little@2x.png"
                      class="img"
                    />
                  </div>
                  <div class="carousel-content-left-two">
                    <img
                      src="../assets/img/home/数据展示big@2x.png"
                      class="img"
                    />
                  </div>
                </div>
                <div class="carousel-content-right">
                  <div class="carousel-content-right-tit">新三板上市 百项著作权技术沉淀</div>
                  <div
                    class="carousel-content-right-item"
                    v-for="(item, index) in advantagesList"
                    :key="index"
                  >
                    <div class="item-round" />
                    <div class="item-content">{{ item }}</div>
                  </div>
                </div>
              </div>
            </el-carousel-item> -->
            <el-carousel-item v-for="item in advantagesList" :key="item">
              <div class="foot-carousel-content">
                <div class="carousel-content-left">
                  <div class="carousel-content-left-one">
                    <img
                      src="../assets/img/home/数据展示little@2x.png"
                      class="img"
                    />
                  </div>
                  <div class="carousel-content-left-two">
                    <img
                      src="../assets/img/home/数据展示big@2x.png"
                      class="img"
                    />
                  </div>
                  <!-- <img
                    class="content-img"
                    src="../assets/img/home/AI大脑@2x.png"
                  /> -->
                </div>
                <div class="carousel-content-right">
                  <div class="carousel-content-right-tit">{{ item.tit }}</div>
                  <div
                    class="carousel-content-right-item"
                    v-for="(inneritem, index) in item.content"
                    :key="index"
                  >
                    <div class="item-round" />
                    <div class="item-content">{{ inneritem }}</div>
                  </div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>

    <!-- <div class="home-record">
      <div class="home-record-title">
        <div class="home-record-round" />
        <div class="title">30万家企业的信赖之选</div>
      </div>
      <div class="home-record-content">
        <img
          src="../assets/img/home/图层 628@2x.png"
          alt="展示图片"
          class="record-left-img"
        >
        <div class="record-right">
          <div
            class="record-content-right"
            v-for="item in recordList"
            :key="item.id"
          >
            <div class="left">
              <div
                class="record-content-num"
                v-show="item.num !== '10万+'"
              >
                {{ item.num }}
              </div>
              <div
                class="record-content-num"
                v-show="item.num === '10万+'"
              >
                10<span>万</span>+
              </div>
              <div class="record-content-text">{{ item.text }}</div>
            </div>
            <img
              :src="item.img"
              alt="展示图片"
              v-if="item.img"
            >
          </div>
        </div>
      </div>
    </div>
    <div class="banner-xia">
      <img src="../assets/img/home/头部下@2x.png" />
    </div> -->

    <div class="home-cooperate">
      <web-cooperate title="合作伙伴" />
    </div>
    <div class="home-footer">
      <web-footer />
    </div>
    <fixed-dialog />
    <fixed-concat />
    <div class="home-video" v-show="isVideo">
      <div class="video-back">
        <div class="video-close" @click="videoClick(false)">x</div>
        <video
          ref="video"
          id="video"
          src="../assets/img/home/video(16).mp4"
          :autoplay="videolist.autoplay"
          :controls="videolist.controls"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import fixedConcat from '../components/FixedConcat/index.vue'
import fixedDialog from '../components/FixedDialog/index.vue'
import webCooperate from '../components/WebCooperate/index.vue'
import webFooter from '../components/WebFooter/index.vue'
import webHeader from '../components/WebHeader/index.vue'

export default {
  name: 'home',
  components: {
    webHeader,
    webCooperate,
    webFooter,
    fixedDialog,
    fixedConcat
  },
  data () {
    return {
      recordList: [
        {
          id: 1,
          num: '17',
          text: '行业深耕/年',
          img: ''
        },
        {
          id: 2,
          num: '100+',
          text: '解决方案数',
          img: ''
        },
        {
          id: 3,
          num: '10万+',
          text: '服务坐席数',
          img: ''
        },
        {
          id: 4,
          num: '10%',
          text: '每年费用降低',
          img: ''
        },
        {
          id: 5,
          num: '20%',
          text: '客户转化率',
          img: require('../assets/img/home/箭头 上升@2x.png')
        },
        {
          id: 6,
          num: '30%',
          text: 'ROI提升',
          img: require('../assets/img/home/箭头 上升@2x.png')
        }
      ],
      brokenList: [7.23, 7.24, 7.25, 7.26, 7.27],
      moduleDate: [
        {
          id: 1,
          img: require('../assets/img/home/5G@2x.png'),
          tit: '5G多媒体消息',
          content: '消息场景化 让营销成为交互式的客户体验',
          contentNum: '80%',
          contentText: '商品转化率提升'
        },
        {
          id: 2,
          img: require('../assets/img/home/news@2x.png'),
          tit: '短消息',
          content: '整合三大运营商通信能力',
          contentNum: '99.99%',
          contentText: '到达率'
        },
        {
          id: 3,
          img: require('../assets/img/home/5G_video@2x.png'),
          tit: '5G视频数字人',
          content: '老客激活|营销转化|新客获取的高效方法',
          contentNum: '90%',
          contentText: '沉浸式讲解提升效果'
        }
      ],
      moduleTwoDate: [
        {
          id: 1,
          tit: '及猫云呼叫系统',
          content:
            '高效触达客户解决员工电销难题！API插件打通集团和门店导购工作手机数智化最后一公里！',
          contentNum: '95%',
          contentText: '筛客效率提升',
          img: require('../assets/img/home/people_left@2x.png')
        },
        {
          id: 2,
          tit: '及猫云助手',
          content: '打造企业私域流量池',
          contentNum: '90%',
          contentText: '赋能销售业绩增长',
          img: require('../assets/img/home/people_right@2x.png')
        }
      ],
      // advantagesList: [
      //   '17年行业会员运营经验！',
      //   '上万家成功案例实践！',
      //   '融合产品的载体有效提升触达能力！'
      // ],
      advantagesList: [
        {
          id: 1,
          tit: '新三板上市 百项著作权技术沉淀',
          content: [
            '17年行业会员运营经验！',
            '上万家成功案例实践！',
            '融合产品的载体有效提升触达能力！'
          ]
        },
        {
          id: 2,
          tit: '稳健的线路资源，合理规划拨打策略，用户更愿意接听',
          content: [
            'GMAI更懂得在合适的时间唤醒沉睡的上帝',
            '打哪里显哪里 企业名片认证',
            '覆盖全国的优质线路 17年通信运营经验',
            '行业专家1V1， 定制品牌专属AI'
          ]
        },
        {
          id: 3,
          tit: '通话数据沉淀和分析，快速迭代企业营销模型',
          content: [
            '用户意图精准识别， "销冠"式AI应答，促进成交',
            '通话内容沉淀 回流企业数据库',
            '通话节点分析 构建策略优化闭环'
          ]
        }
      ],
      options: { time: 1500, num: 199309, regulator: 20 },
      videolist: {
        // 自动播放
        autoplay: false,
        // 是否显示默认播放控件
        controls: 'controls'
      },
      isVideo: false,
      valid: true
    }
  },
  methods: {
    experienceClick () {
      // window.open('https://uxus7n.fanqier.cn/f/i9x2f1e6', '_blank')
      var str = location.href.split('#')[0]
      window.open(str + '#/messageInformation', '_blank')
    },
    // 数字增长变化
    changeSeatNum (targetEle, options) {
      options = options || {}
      var $this = document.getElementById(targetEle)
      // 总时间--单位是毫秒
      var time = options.time || 1500
      // 要显示的真实数值
      var finalNum = options.num || 0
      // 调速器
      var regulator = options.regulator || 100
      // 每30毫秒增加的数值
      var step = finalNum / (time / regulator)
      // 计数器
      var count = 0
      var initial = 0
      var timer = setInterval(function () {
        count = count + step
        if (count >= finalNum) {
          clearInterval(timer)
          count = finalNum
        }
        var t = Math.floor(count)
        if (t === initial) return
        initial = t
        if (initial > 3) {
          var stringNum = initial.toString()
          var beforNum = stringNum.substring(0, stringNum.length - 3)
          var afterNum = stringNum.split('').slice(3).join('')
          $this.innerHTML = beforNum + ',' + afterNum
        }
      }, 30)
    },
    videoClick (val) {
      this.isVideo = val
      val && this.$refs.video.play()
      !val && this.$refs.video.pause()
    },
    addEventHeight () {
      // 滚动到这里再数字变动
      // var scroll = document.documentElement.scrollTop || document.body.scrollTop
      // if (scroll > 1094 && this.valid) {
      console.log('scroll', scroll)
      if (this.valid) {
        this.valid = false
        this.getEchartData()
        this.changeSeatNum('num', this.options)
      }
    },
    getEchartData () {
      var dateArr = []
      var dateOne = moment().format('MM-DD')
      dateOne = this.changeDate(dateOne)
      var datetwo = moment().subtract(1, 'days').format('MM-DD')
      datetwo = this.changeDate(datetwo)
      var datethree = moment().subtract(2, 'days').format('MM-DD')
      datethree = this.changeDate(datethree)
      var datefour = moment().subtract(3, 'days').format('MM-DD')
      datefour = this.changeDate(datefour)
      var datefive = moment().subtract(4, 'days').format('MM-DD')
      datefive = this.changeDate(datefive)
      dateArr.push(datefive, datefour, datethree, datetwo, dateOne)
      var chart = this.$refs.chart
      if (chart) {
        var myChart = this.$echarts.init(chart)
        var option = {
          tooltip: {
            show: false,
            trigger: 'axis'
          },
          grid: {
            left: '2%',
            right: '3%',
            bottom: '20%',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: dateArr,
            axisLabel: {
              interval: 0,
              textStyle: {
                color: '#222323',
                fontWeight: 500,
                fontFamily: 'PingFang SC',
                fontSize: 18
              }
            },
            axisTick: {
              show: false
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: '#E5E9ED'
              }
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: '#E5E9ED'
              }
            }
          },
          yAxis: [
            {
              type: 'value',
              splitNumber: 5,
              axisLabel: {
                show: false,
                textStyle: {
                  color: '#a8aab0',
                  fontStyle: 'normal',
                  fontFamily: '微软雅黑',
                  fontSize: 12
                }
              },
              axisLine: {
                show: false
              },
              axisTick: {
                show: false
              },
              splitLine: {
                show: false
              }
            }
          ],
          series: [
            {
              name: '2018',
              type: 'line',
              symbol: 'none',
              itemStyle: {
                normal: {
                  color: '#ffe50c',
                  lineStyle: {
                    color: '#ffe50c',
                    width: 2
                  },
                  areaStyle: {
                    color: this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [
                      {
                        offset: 0,
                        color: 'rgba(255,229,12,0)'
                      },
                      {
                        offset: 1,
                        color: 'rgba(255,229,12,0.65)'
                      }
                    ])
                  }
                }
              },
              data: [
                {
                  value: 0.9,
                  symbol: 'circle',
                  symbolSize: '10'
                },
                2,
                3,
                3.6,
                4
              ]
            }
          ]
        }
        myChart.setOption(option)
        window.addEventListener('resize', function () {
          myChart.resize()
        })
      }
      this.$on('hook:destroyed', () => {
        window.removeEventListener('resize', function () {
          myChart.resize()
        })
      })
    },
    changeDate (date) {
      if (!date[0]) return false
      if (date.split('-')[0].slice(0, 1) === '0') {
        return date.split('-')[0].slice(1) + '.' + date.split('-')[1]
      } else {
        return date.split('-')[0] + '.' + date.split('-')[1]
      }
    }
  },
  mounted () {
    // window.addEventListener('scroll', this.addEventHeight)
    this.addEventHeight()
  },
  destroyed () {
    // window.removeEventListener('scroll', this.addEventHeight)
  }
}
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  .home-carousel {
    ::v-deep .el-carousel__container {
      height: 747px;
    }
    ::v-deep .el-carousel__indicators--horizontal {
      top: 650px;
    }
    ::v-deep .el-carousel__indicator.is-active button {
      background: #ffffff;
    }
  }
  .home-footer {
    margin-top: -100px;
  }
  .home-head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 747px;
    background-image: url('../assets/img/backImg@2x.png');
    background-size: 100% 100%;
    padding-left: 90px;
    padding-right: 63px;
    padding-bottom: 150px;
      .first-page-bgc{
        width: 1000px;
        height: 650px;
        background-image: url('../assets/img/home/背景.png');
        background-size: 100% 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
    .home-head-left {
       z-index: 1;
      .home-first-head-content{
        font-size: 44px;
        font-weight: bold;
        color: #000000;
        line-height: 68px;
        margin: 23px 0 30px 0;
      }
      .home-head-title {
        display: flex;
        // margin-top: 10px;
        .first-title {
          font-size: 40px;
          font-weight: 500;
          color: #212325;
          z-index: 1;
          margin-left: -30px;
          margin-top: 20px;
          font-family: Montserrat-Regular, 'pingfang SC', PingFang-Web, '微软雅黑';
        }
        .round {
          position: relative;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          margin: 28px 0px 0 -4px;
          .first-round-two {
            position: absolute;
            top: 0;
            left: 0;
            width: 40px;
            height: 40px;
            background: #ffffff;
            border-radius: 50%;
            opacity: 0.58;
            margin-top: 65px;
            margin-left: -7px;
            z-index: -1;
          }
          .round-one {
            width: 100%;
            height: 100%;
            background: #ebca57;
            border-radius: 50%;
          }
          .round-two {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #ffffff;
            border-radius: 50%;
            opacity: 0.58;
          }
        }
        .title {
          font-size: 58px;
          font-weight: 800;
          color: #212325;
          z-index: 1;
          margin-left: -30px;
          font-family: Montserrat-Regular, 'pingfang SC', PingFang-Web,
            '微软雅黑';
          display: flex;
          align-items: center;
          img {
            width: 164px;
            height: 152px;
            margin-right: 30px;
          }
        }
        .outer-title {
          display: flex;
          flex-direction: column;
        }
        .title-one {
          margin-top: 20px;
          padding-left: 250px;
          font-size: 58px;
          font-weight: 800;
          color: #212325;
          z-index: 1;
          margin-left: -30px;
          font-family: Montserrat-Regular, 'pingfang SC', PingFang-Web,
            '微软雅黑';
        }
      }
      .home-head-content {
        font-size: 40px;
        font-family: Montserrat-Regular, 'pingfang SC', PingFang-Web, '微软雅黑';
        font-weight: 500;
        color: #212325;
        line-height: 68px;
        margin: 63px 0 30px 0;
      }
      .home-head-detail-border {
        width: 729px;
        .home-head-detail-item {
          border: 1px solid black;
          width: 200px;
          .home-head-detail-item-title {
            color: #585858;
            font-size: 18px;
            font-family: Montserrat-Regular, 'pingfang SC', PingFang-Web,
              '微软雅黑';
            font-weight: 540;
            text-align: center;
          }
          .home-head-detail-item-content {
            color: #585858;
          }
        }
      }
      .home-head-content-details {
        font-size: 26px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #071b3c;
        margin-top: 68px;
        margin-bottom: 75px;
      }
      .home-head-detail-content {
        width: 729px;
        color: #585858;
        font-size: 18px;
        font-family: Montserrat-Regular, 'pingfang SC', PingFang-Web, '微软雅黑';
        font-weight: 540;
      }
    }
    .home-center-left {
      margin-left: 120px;
      .home-center-title {
        display: flex;
        .round {
          position: relative;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          margin: 24px 0px 0 -4px;
          .round-one {
            width: 100%;
            height: 100%;
            background: #ebca57;
            border-radius: 50%;
          }
          .round-two {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #ffffff;
            border-radius: 50%;
            opacity: 0.58;
          }
        }
        .title {
          font-size: 60px;
          font-weight: 800;
          color: #212325;
          z-index: 1;
          margin-left: -30px;
          font-family: Montserrat-Regular, 'pingfang SC', PingFang-Web,
            '微软雅黑';
        }
      }
      .home-center-content {
        font-size: 26px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #071b3c;
        margin-top: 68px;
        margin-bottom: 75px;
      }
      .home-center-btn {
        font-size: 24px;
        font-family: SourceHanSansCN;
        font-weight: 500;
        color: #ffe50c;
        width: 205px;
        height: 61px;
        background: #262626;
        border-radius: 31px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          cursor: pointer;
          color: #071b3d;
          background: #ffe50c;
          border: 2px solid #071b3c;
        }
      }
    }
    .home-head-right-one {
      position: relative;
      width: 750px;
      height: 482px;
      .show-img-one {
        width: 427px;
        height: 580px;
      }
      .show-img {

        width: 100%;
        height: 100%;
      }
      .open-img {
        position: absolute;
        top: 215px;
        left: 404px;
        width: 119px;
        height: 119px;
      }
    }
    .home-head-right {
      position: relative;
      width: 1000px;
      height: 600px;
      .show-img-first {
        // 缩放
        transform: scale(0.9);
        margin-left: 150px;
        margin-top: 50px;
      }
      .show-img-one {
        width: 427px;
        height: 580px;
      }
      .show-img {
        transform: scale(0.8);
        width: 100%;
        height: 100%;
      }
      .open-img {
        position: absolute;
        top: 215px;
        left: 404px;
        width: 119px;
        height: 119px;
      }
    }
    .call-center {
      width: 510px;
      height: 521px;
      margin-right: 316px;
    }
  }

  .home-record {
    width: 100%;
    padding-bottom: 94px;
    .home-record-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin: 110px 0 127px 0;
      .home-record-round {
        width: 25px;
        height: 25px;
        background: #ffd400;
        border-radius: 50%;
        margin-right: -24px;
      }
      .title {
        font-size: 45px;
        font-family: SourceHanSansCN;
        font-weight: bold;
        color: #000000;
      }
    }
    .home-record-content {
      display: flex;
      .content-img {
        height: 502px;
        width: 489px;
        // margin-left: 95px;
        margin-left: 214px;
        margin-right: 292px;
        &:hover {
          animation: float 4s ease-in-out infinite;
          @keyframes float {
            0% {
              transform: translateY(0px);
            }
            50% {
              transform: translateY(-10px);
            }
            100% {
              transform: translateY(0px);
            }
          }
        }
      }
      .record-left-img {
        width: 524px;
        height: 291px;
        margin-left: 95px;
        margin-right: 292px;
        &:hover {
          animation: float 4s ease-in-out infinite;
          @keyframes float {
            0% {
              transform: translateY(0px);
            }
            50% {
              transform: translateY(-10px);
            }
            100% {
              transform: translateY(0px);
            }
          }
        }
      }
      .record-right {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .record-content-right {
          display: flex;
          align-items: center;
          margin-right: 150px;
          &:last-child {
            margin-left: -50px;
          }
          &:nth-child(4) {
            margin-right: 146px;
          }
          .left {
            .record-content-num {
              font-size: 40px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #333333;
              display: flex;
              flex-direction: row;
              span {
                font-size: 35px;
                margin-top: 2px;
                display: block;
              }
            }
            .record-content-text {
              font-size: 22px;
              font-family: PingFang SC;
              font-weight: 500;
              // color: #333333;
              color: #a3acb4;
              margin-top: 26px;
            }
          }
          img {
            width: 19px;
            height: 25px;
            margin-left: 34px;
          }
        }
      }
    }
  }
  .banner-xia {
    width: 100%;
    // padding-top: 20px;
    // padding-bottom: 20px;
    background-color: #f8f8f8;
    padding: 20px 126px 10px 85px;

    img {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      // padding: 0 126px 0 85px;
      box-shadow: 0px 0px 177px 0px rgba(0, 0, 0, 0.05);
      border-radius: 30px;
    }
  }
  .home-picture {
    padding-top: 50px;
    width: 100%;
    background: #f8f8f8;
    .home-piceture-title {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 158px 0 113px 0;
      .round {
        width: 25px;
        height: 25px;
        background: #ffd400;
        border-radius: 50%;
        margin-right: -20px;
        margin-top: 4px;
      }
      .title {
        font-size: 45px;
        font-family: SourceHanSansCN;
        font-weight: bold;
        color: #000000;
        span {
          color: #ffd400;
        }
      }
    }
    .home-picture-one {
      background: #ffffff;
      //  box-shadow: 0px 0px 177px 0px rgba(0, 0, 0, 0.05);
      border-radius: 30px;
      display: flex;
      flex-direction: row;
      margin: 0 126px 30px 85px;
      padding: 70px 76px 45px 85px;
      .one-left {
        margin-right: 158px;
        .one-left-title {
          .title {
            font-size: 32px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #000000;
          }
          .underline {
            width: 214px;
            height: 13px;
            background: #ffe50c;
            border-radius: 3px;
            margin-top: -18px;
            margin-left: 8px;
          }
        }
        .one-left-content-one {
          font-size: 22px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #a3acb4;
          margin-top: 38px;
          margin-bottom: 20px;
        }
        .one-left-content-two {
          font-size: 22px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #a3acb4;
          margin-bottom: 49px;
        }
        .one-left-btn {
          width: 160px;
          height: 50px;
          background: #262626;
          border-radius: 31px;
          font-size: 18px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #ffe50c;
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 46px;
        }
      }
      .one-right {
        .one-right-tit-little {
          font-size: 22px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #000000;
        }
        .one-right-tit-big {
          font-size: 65px;
          font-family: PingFang SC;
          font-weight: 600px;
          color: #000000;
          margin-top: 16px;
          margin-bottom: -16px;
          span {
            font-weight: 400;
            font-size: 50px;
          }
        }
        .broken-line {
          width: 1030px;
          height: 230px;
          margin-top: -80px;
        }
        .one-right-num {
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #222323;
          width: 994px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
      }
    }
    .home-picture-two {
      margin: 34px 126px 36px 85px;
      display: flex;
      justify-content: space-between;
      .home-picture-all {
        width: 538px;
        height: 515px;
        background: #ffffff;
        border-radius: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        .home-picture-two-head {
          padding-top: 56px;
          display: flex;
          flex-direction: column;
          align-items: center;
          .title-img {
            display: flex;
            img {
              width: 49px;
              height: 45px;
              margin-right: 14px;
            }
            .title {
              font-size: 32px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #000000;
            }
          }
          .content {
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #a3acb4;
            margin-top: 23px;
          }
        }
        .home-picture-two-content {
          display: flex;
          flex-direction: column;
          align-items: center;
          .content-num {
            font-size: 70px;
            font-family: PingFang SC;
            font-weight: 600;
            color: #000000;
          }
          .content-text {
            font-size: 22px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #000000;
          }
        }
        .home-picture-two-footer {
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #ffd400;
          line-height: 46px;
          width: 160px;
          height: 50px;
          background: #262626;
          border-radius: 31px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 43px;
        }
      }
    }
    .home-picture-three {
      margin: 34px 126px 36px 85px;
      display: flex;
      justify-content: space-between;
      .home-picture-three-all {
        width: 824px;
        height: 515px;
        background: #ffffff;
        border-radius: 30px;
        display: flex;
        justify-content: space-between;
        .three-all-left {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-left: 66px;
          .three-all-head {
            margin-top: 57px;
            .head-tit {
              font-size: 36px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #000000;
            }
            .head-content {
              // font-size: 22px;
              font-size: 20px;
              // line-height: 22px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #a3acb4;
              margin-top: 20px;
            }
          }
          .three-all-content {
            .three-content-num {
              font-size: 70px;
              font-family: PingFang SC;
              font-weight: 600;
              color: #000000;
            }
            .three-content-text {
              font-size: 24px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #000000;
              margin-top: 2px;
            }
          }
          .three-all-btn {
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #ffd400;
            line-height: 46px;
            width: 160px;
            height: 50px;
            background: #262626;
            border-radius: 31px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 32px;
          }
        }
        .three-all-right {
          width: 267px;
          height: 517px;
        }
      }
    }
    .at-one-try-out {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 117px;
      padding-bottom: 97px;
      .btn {
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #ffe50c;
        line-height: 46px;
        width: 190px;
        height: 70px;
        background: #262626;
        border-radius: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 2px;
      }
    }
    .home-picture-four {
      width: 100%;
      background: #ffffff;
      display: flex;
      flex-direction: row;
      align-items: center;
      .home-piceture-four-left {
        width: 452px;
        height: 462px;
        margin: 73px 212px 23px 200px;
      }
      .home-piceture-four-right {
        display: flex;
        flex-direction: row;
        .four-tit-left {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          .tit-left-head {
            margin-bottom: 121px;
            .head-title {
              font-size: 52px;
              font-family: SourceHanSansCN;
              font-weight: bold;
              color: #262626;
            }
            .head-line {
              width: 500px;
              height: 8px;
              background: #ffd400;
              border-radius: 10px;
              margin-top: -18px;
              margin-left: 19px;
            }
          }
          .tit-left-foot {
            display: flex;
            flex-direction: row;
            align-items: center;
            .foot-trigonum {
              width: 0;
              height: 0;
              border-left: 15px solid #ffd400;
              border-top: 6px solid transparent;
              border-bottom: 6px solid transparent;
              margin-right: 6px;
            }
            .foot-title {
              font-size: 48px;
              font-family: SourceHanSansCN;
              font-weight: bold;
              font-style: italic;
              color: #262626;
              margin-left: 4px;
            }
          }
        }
        .four-picture-right {
          display: flex;
          flex-direction: column;
          .four-picture-right-one {
            width: 154px;
            height: 92px;
            margin-left: 85px;
            margin-top: 18px;
          }
          .four-picture-right-two {
            width: 205px;
            height: 163px;
            margin-top: -16px;
            margin-left: 29px;
          }
        }
      }
    }
    .home-picture-five {
      width: 100%;
      padding-bottom: 107px;
      .home-picture-five-head {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 105px;
        .five-head {
          display: flex;
          align-items: center;
          .five-head-round {
            width: 25px;
            height: 25px;
            background: #ffd400;
            border-radius: 50%;
            margin-right: -16px;
          }
          .five-head-tit {
            font-size: 50px;
            font-family: SourceHanSansCN;
            font-weight: bold;
            color: #071b3d;
          }
        }
        .five-head-tit-english {
          font-size: 26px;
          font-family: SourceHanSansCN;
          font-weight: 500;
          color: rgba(7, 27, 61, 0.56);
          margin-top: 21px;
          margin-left: 5px;
        }
      }
      .home-picture-five-foot {
        margin-top: 136px;
        ::v-deep .el-carousel__button {
          background: #ffffff;
          border: 1px solid #ffe50c;
        }
        ::v-deep .el-carousel__indicator.is-active button {
          background: #ffe50c;
        }
        .foot-carousel-content {
          display: flex;
          flex-direction: row;
          .carousel-content-left {
            display: flex;
            flex-direction: column;
            margin-left: 89px;
            margin-right: 77px;
            .content-img {
              margin-left: 206px;
              margin-right: 100px;
              height: 502px;
              width: 489px;
            }
            .carousel-content-left-one {
              width: 488px;
              height: 322px;
              margin-left: 299px;
              z-index: 2;
              box-shadow: 0px 2px 70px 9px rgba(98, 166, 237, 0.18);
              border-radius: 10px;
              .img {
                width: 504px;
                height: 336px;
                margin-left: -8px;
                margin-top: -5px;
              }
            }
            .carousel-content-left-two {
              width: 667px;
              height: 388px;
              margin-top: -246px;
              z-index: 1;
              border-image: linear-gradient(185deg, #000019, #ffffff) 10 10;
              background: linear-gradient(185deg, #000019 0%, #ffffff 100%);
              border-radius: 10px;
              .img {
                width: 679px;
                height: 399px;
                margin-top: -5px;
                margin-left: -6px;
                z-index: 1;
              }
            }
          }
          .carousel-content-right {
            .carousel-content-right-tit {
              font-size: 40px;
              font-family: SourceHanSansCN;
              letter-spacing: 4px;
              font-weight: bold;
              font-style: italic;
              color: #071b3d;
              line-height: 47px;
              margin-top: 53px;
              margin-bottom: 75px;
            }
            .carousel-content-right-item {
              display: flex;
              flex-direction: row;
              align-items: center;
              margin-left: 20px;
              margin-bottom: 59px;
              .item-round {
                width: 18px;
                height: 18px;
                background: #ffd400;
                border-radius: 50%;
              }
              .item-content {
                font-size: 25px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #071b3d;
                line-height: 41px;
                margin-left: -10px;
              }
            }
          }
        }
      }
    }
  }
  .home-cooperate {
    width: 100%;
  }
  .home-video {
    width: 100%;
    height: 100%;
    background: rgba(18, 20, 26, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 110;
    display: flex;
    justify-content: center;
    align-items: center;
    .video-back {
      width: 1200px;
      height: 800px;
      background-color: #000;
      display: flex;
      flex-direction: column;
      .video-close {
        font-size: 20px;
        color: #ffffff;
        cursor: pointer;
        margin-left: auto;
        margin-right: 24px;
        margin-top: 24px;
        margin-bottom: 12px;
      }
    }
    #video {
      width: 100%;
      height: 678px;
    }
  }
}
</style>
