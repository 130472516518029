<template>
  <div class="login"></div>
</template>

<script>
export default {
  name: 'Login'
}
</script>

<style lang="scss" scoped>
.login {}
</style>
