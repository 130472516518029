<template>
  <div
    id="app"
    class="min-wid"
  >
    <!-- <web-header v-if="flag"></web-header> -->
    <router-view />
    <!-- <web-footer></web-footer> -->
  </div>
</template>
<script>
// import webHeader from '@/components/webHeader'
// import webFooter from '@/components/webFooter'
export default {
  data () {
    return {
      flag: true
    }
  },
  watch: {
    $route (to, from) {
      // to 和 from 都是 路由信息对象
      console.log('to', to)
      console.log('from', from)
      if (to.path == '/login') {
        this.flag = false
      }
    }
  },
  created () { },
  methods: {},
  components: {
    // webHeader
    // webFooter
  },
  mounted: function () {

  }
}
</script>

 <style lang="scss">
// * {
//   margin: 0;
//   padding: 0;
//   list-style: none;
// }
// html,
// body {
//   width: 100%;
//   height: 100%;
// }
// #app {
//   width: 100%;
//   height: 100%;
// }
* {
  margin: 0;
  padding: 0;
  list-style: none;
}
html,
body {
  width: 100%;
  height: 100%;
}
#app {
  min-width: 1200px;
  width: 100%;
  height: 100%;
  position: relative;
}
</style>
