<template>
  <div class="fixed-concat" v-show="isFixedConcat">
    <div class="fixed-concat-head">
      <div
        class="item ph"
        @mouseover="onmouseoverPhone(true)"
        @mouseout="onmouseoverPhone(false)"
      >
        <img
          :src="phoneImg"
          alt
          class="phone"
        />
        <div class="ph-dialog" v-show="isPhone">
          <div class="ph-trigonum" />
        </div>
        <div class="is-img" v-show="isPhone">
          <img src="../../assets/img/微信二维码@2x.png" alt />
          <div class="tit">添加微信1对1咨询</div>
        </div>
      </div>
      <div class="fixed-concat-line" v-show="phoneShow" />
      <div
        class="item wx"
        @mouseover="onmouseoverWeixin(true)"
        @mouseout="onmouseoverWeixin(false)"
      >
        <img
          :src="weixinImg"
          alt
          class="weixin"
        />
        <div class="wx-dialog" v-show="isWeixin">
          <div class="wx-trigonum" />
        </div>
        <div class="is-img" v-show="isWeixin">
          <img src="../../assets/img/微信二维码@2x.png" alt />
          <div class="tit">添加微信1对1咨询</div>
        </div>
      </div>
      <div class="fixed-concat-line" v-show="weixinShow" />
      <div class="item ne"
        @click="experienceClick"
        @mouseover="onmouseoverNews(true)"
        @mouseout="onmouseoverNews(false)"
      >
        <img
          :src="newsImg"
          alt
          class="news"
        />
        <div class="is-img"></div>
      </div>
    </div>
    <div
      class="fixed-concat-foot"
      @mouseover="onmouseoverBack(true)"
      @mouseout="onmouseoverBack(false)"
    >
      <img
        :src="backImg"
        alt
        @click="backClick"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'fixed-concat',
  data () {
    return {
      isPhone: false,
      phoneImg: require('../../assets/img/24gf-telephone@2x.png'),
      isWeixin: false,
      weixinImg: require('../../assets/img/微信@2x.png'),
      isNews: false,
      newsImg: require('../../assets/img/申请@2x.png'),
      isBack: false,
      backImg: require('../../assets/img/返回顶部 @2x.png'),
      isFixedConcat: false
    }
  },
  mounted () {
    // 滚动大于一定范围时固定导航隐藏
    this.listenerFunction()
  },
  methods: {
    onmouseoverPhone (val) {
      this.isPhone = val
      this.phoneImg = !val ? require('../../assets/img/24gf-telephone@2x.png') : require('../../assets/img/back-telephone@2x.png')
    },
    onmouseoverWeixin (val) {
      this.isWeixin = val
      this.weixinImg = !val ? require('../../assets/img/微信@2x.png') : require('../../assets/img/back_微信@2x.png')
    },
    onmouseoverNews (val) {
      this.isNews = val
      this.newsImg = !val ? require('../../assets/img/申请@2x.png') : require('../../assets/img/back_申请@2x.png')
    },
    onmouseoverBack (val) {
      this.isBack = val
      this.backImg = !val ? require('../../assets/img/返回顶部 @2x.png') : require('../../assets/img/back_返回顶部@2x.png')
    },
    experienceClick () {
      var str = location.href.split('#')[0]
      window.open(str + '#/messageInformation', '_blank')
    },
    backClick () {
      var timer = setInterval(function () {
        const osTop = document.documentElement.scrollTop || document.body.scrollTop
        const ispeed = Math.floor(-osTop / 5)
        document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed
        this.isTop = true
        if (osTop === 0) {
          clearInterval(timer)
        }
      }, 30)
    },
    listenerFunction (e) {
      document.addEventListener('scroll', this.handleScroll, true)
    },
    beforeDestroy () {
      document.removeEventListener('scroll', this.listenerFunction)
    },
    handleScroll () {
      if (window.pageYOffset > 380) {
        this.isFixedConcat = true
      } else {
        this.isFixedConcat = false
      }
    }
  },
  computed: {
    phoneShow () {
      if (this.isPhone) {
        return !this.isPhone
      } else {
        return !this.isWeixin
      }
    },
    weixinShow () {
      if (this.isWeixin) {
        return !this.isWeixin
      } else {
        return !this.isNews
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.fixed-concat {
  position: fixed;
  right: 0;
  bottom: 410px;
  z-index: 101;
  display: none;
  .fixed-concat-head {
    width: 102px;
    background: #262626;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1px;
    padding-bottom: 1px;
    .item {
      width: 74px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #FFE50B;
    }
    .item.ph {
      height: 95px;
      position: relative;
      .is-img {
        position: absolute;
        width: 238px;
        height: 278px;
        background: #ccc;
        right: 159px;
        top: 11px;
        background: #F8F8F8;
        box-shadow: 0px 4px 49px 13px rgba(55,53,53,0.13);
        border-radius: 4px;
        img {
          width: 185px;
          height: 184px;
          margin-bottom: 10px;
          margin-top: 27px;
          margin-left: 26px;
        }
        .tit {
          font-size: 18px;
          font-family: SourceHanSansCN;
          font-weight: 500;
          color: #000000;
          text-align: center;
        }
      }
      &:hover {
        border-radius: 25px 25px 0 0;
        background: #ffdb41;
        width: 100px;
      }
      .ph-dialog {
        position: absolute;
        right: 100px;
        top: 45px;
        width: 0;
        height: 0;
        border-top: 9px solid transparent;
        border-right: 13px solid #262626;
        border-bottom: 8px solid transparent;
        .ph-trigonum {
          width: 0;
          height: 0;
          border-top: 8px solid transparent;
          border-right: 12px solid #FFD858;
          border-bottom: 6px solid transparent;
          margin-left: 2px;
          margin-top: -8px;
        }
      }
    }
    .item.wx {
      height: 85px;
      position: relative;
      .is-img {
        position: absolute;
        width: 238px;
        height: 278px;
        background: #F8F8F8;
        box-shadow: 0px 4px 49px 13px rgba(55,53,53,0.13);
        border-radius: 4px;
        right: 159px;
        top: -30px;
        img {
          width: 185px;
          height: 184px;
          margin-bottom: 10px;
          margin-top: 27px;
          margin-left: 26px;
        }
        .tit {
          font-size: 18px;
          font-family: SourceHanSansCN;
          font-weight: 500;
          color: #000000;
          text-align: center;
        }
      }
      &:hover {
        background: #ffdb41;
        width: 100px;
      }
      .wx-dialog {
        position: absolute;
        right: 100px;
        top: 40px;
        width: 0;
        height: 0;
        border-top: 9px solid transparent;
        border-right: 13px solid #262626;
        border-bottom: 8px solid transparent;
        .wx-trigonum {
          width: 0;
          height: 0;
          border-top: 8px solid transparent;
          border-right: 12px solid #FFD858;
          border-bottom: 6px solid transparent;
          margin-left: 2px;
          margin-top: -8px;
        }
      }
    }
    .item.ne {
      height: 95px;
      &:hover {
        border-radius: 0 0 25px 25px;
        background: #ffdb41;
        width: 100px;
      }
    }
    .phone {
      width: 38px;
      height: 38px;
    }
    .weixin {
      width: 38px;
      height: 28px;
    }
    .news {
      width: 42px;
      height: 33px;
    }
    .fixed-concat-line{
      width: 74px;
      height: 1px;
      background: #FFE50B;
      // margin-left: 15px;
    }
  }
  .fixed-concat-foot {
    width: 102px;
    height: 96px;
    background: #262626;
    border-radius: 30px;
    margin-top: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background: #ffdb41;
    }
    img {
      width: 32px;
      height: 54px;
    }
  }
}
</style>
